
main.curriculum(
  :class="(show ? 'show' : 'hide') + (trueHide ? ' true-hide' : '')"
)
  .mask
  #iscroll.wrapper
    ul.content
      li.item
        .date
          .year October 2023 – now
        .thing
          .occupation IT Project & OPS
          .location
            a(href="https://Asri.co.id" target="_blank") ASRI
              inline-svg(:src="svgNewWindow")
            | , Pik, Jakarta
      li.item
        .date
          .year June 2022 – March 2023
        .thing
          .occupation IT Networking 
          .location
            a(href="https://Larissa.co.id" target="_blank") Larissa
              inline-svg(:src="svgNewWindow")
            | , Sleman, Yogyakarta
      li.item
        .date
          .year July 2019 – November 2019
        .thing
          .occupation Web Programmer (intern)
          .location BMKG, Sleman, Yogykarta
    
      li.item
        .date
          .skill-tag skills
        .thing
          span.category(v-for="category in skillsArray")
            span.type {{ category.type }}
            span.skills
              span(v-for="skill in category.content") {{ skill + ', ' }}
     
