
.nav-wrapper(:class="navWrapperClasses")
  .mobile-menu(:class="value ? 'show' : 'hide'")
    svg.burger(
      viewBox="-1 -1 32 32"
      fill="none"
      stroke-width="2"
      @click="onClickBurger"
    )
      g
        line(
          x1="2.5"
          y1="5"
          x2="27.5"
          y2="5"
        )
      g
        line(
          x1="2.5"
          y1="15"
          x2="27.5"
          y2="15"
        )
      g
        line(
          x1="2.5"
          y1="25"
          x2="27.5"
          y2="25"
        )
      g
        line(
          x1="5"
          y1="5"
          x2="25"
          y2="25"
        )
      g
        line(
          x1="5"
          y1="25"
          x2="25"
          y2="5"
        )
  nav(:class="[...navClasses, value ? 'show' : 'hide']")
    .menu-wrapper
      .menu
        a.home(href="/" @click="onClickLink")
          inline-svg(:src="svgEk")
        a(
          href="/about"
          :class="aboutClass"
          @click="onClickLink"
        ) about
        a(
          href="/projects"
          :class="projectsClass"
          @click="onClickLink"
        ) projects
        a(
          href="/curriculum"
          :class="curriculumClass"
          @click="onClickLink"
        ) curriculum
      .social
        a.twitter(href="https://www.linkedin.com/in/gagasanda/" target="_blank") linkedin
        a.github(href="https://github.com/gagasanda" target="_blank") github
        a.mailto(href="mailto:me") mail
