
.cursor(:class="classes")
  .cursor-content
  .social-picto.fifteen
    inline-svg(:src="svgFifteenLogoOnly")
  .social-picto.twitter
    inline-svg(:src="svgTwitter")
  .social-picto.github
    inline-svg(:src="svgGithub")
  .social-picto.mailto
    inline-svg(:src="svgMail")
  .social-picto.new-window
    inline-svg(:src="svgCursorNewWindow")
  .scroll-picto
    inline-svg(:src="svgCursorScroll")
