
main.about(:class="(show ? 'show' : 'hide') + (trueHide ? ' true-hide' : '')")
  .summary
    h1
      | Hi, my name is&nbsp;
      a(href="mailto:me") Gagas Sanda
      | .
      br
      | I’m a Project Planner, currently working at&nbsp;
      a.fifteen(href="https://asri.co.id" target="_blank") ASRI.
    h2
      | I like interact with other people . From a informathics background, i loveeee IT world so bad..
    h3
      a.scroll-invite(href="/projects") Get to know me.
