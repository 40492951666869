
#content.app-content
  physical-bubble(
    :open="bubbleIntro.open"
    :appear="introAppear"
    @end-opening="endOpeningHandler"
    @end-closing="endClosingHandler"
    :num-particles="bubbleIntro.numParticles"
    :settings="bubbleIntro.settings"
    :breath="bubbleIntro.breath"
    :change-blob-color="bubbleIntro.changeBlobColor"
    :has-touch="hasTouch"
    :remove-particles="bubbleIntro.removeParticlesComunicator"
  )
  physical-bubble(
    :num-particles="bubbleLanding.numParticles"
    :settings="bubbleLanding.settings"
    :start="bubbleLanding.start"
    :hide="bubbleLanding.hide"
    :breath="bubbleLanding.breath"
    :projects-mode="bubbleLanding.projectsMode"
    :appear="landingAppear"
    :has-touch="hasTouch"
    :remove-particles="bubbleLanding.removeParticlesComunicator"
  )

  .content-wrapper(:class="content.showHideClass")
    curriculum-content(
      :show="curriculum.show"
      :skills-array="curriculum.skillsArray"
      @on="curriculumOnHandler"
      @off="curriculumOffHandler"
      @reached-top="curriculumReachedTop"
      @scroll="curriculumScrollHandler"
    )
    project-content(
      :show="project.show"
      :id="project.id"
      :project="project.project"
      :origin="project.origin"
      :center-position="project.centerPosition"
      :has-touch="hasTouch"
    )
    the-menu(:value="bubbleIntro.open" :route="route")
    about-content(:show="about.show" :true-hide="about.trueHide")

  a(href="/about")
    intro-logo(:do-hide="intro.logo.doHide" :do-show="intro.logo.doShow")
  intro-text(
    :do-hide="intro.text.doHide"
    :do-show="intro.text.doShow"
    :not-found="intro.text.notFound"
  )
