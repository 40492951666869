export default [
  {
    type: 'Network',
    content: [
      'Topology Design',
      'System Workflow Design',
      'Cisco',
      'Mikrotik',
      'Aruba Central',
      'Omada Tp-link',
      'Troubleshooting',
      'Routing',
      'Subneting',
      'Switching',
      'Monitoring',
      'TCP/IP',
      'OSI Layers',
    ],
  },
  {
    type: 'Server',
    content: [
      'Linux',
      'Windows Server',
      'Ubuntu',
      'Debian',
      'Virtualization',
      'Container',
      'Proxmox',
      'Grafana',
      'Promethus',
      'VMware',
      'XCP-ng',
      'KVM QEMU',
      'SSH',
      'SQL',
      'Zabbix',
      'Apache',
      'NGINX',
    ],
  },
  {
    type: 'Programming',
    content: [
      'Native PHP',
      'Laravel',
      'HTML',
      'CSS',
      'Js',]
  },
  {
    type: 'Devops',
    content: [
      'Docker',
      'Kubernetes',
      'GIT',
      'Ansible',
      'Terraform',
      'Github Action',
      'Jenkins',
      'AWS',
      'GCP',
      'Azure',
      'OCI',
    ]
  },
  {
    type: 'Project',
    content: ['Management', 'Controling','Decission making', 'Monitoring', 'Reporting', ],
  },
  {
    type: 'Methodology',
    content: ['Agile', 'SCRUM'],
  },
  {
    type: 'languages',
    content: ['English', 'Bahasa', 'Javanese'],
  },
];
